import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Seperator } from '@atoms/layout'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { BreadcrumbFragment, ProductListFragment, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { ProductCard } from '@molecules/store/ProductCard'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  position: relative;
  ${ResponsivePXValue('overflow', { mobile: 'hidden' })}
  ${ResponsivePXValue('padding', { mobile: '12px 0 12px 16px', tablet: '0 ', desktop: '0' })}
  ${ResponsivePXValue('margin-top', { tablet: '8px', desktop: '8px' })}
  
  .mobile-all {
    ${ResponsivePXValue('margin', '16px 0 0')}
    ${ResponsivePXValue('width', 'CALC(100% - 16px)')}
  }
`

const Spacer = styled.div`
  position: relative;
`

const Header = styled.div<{ sliderKey: number }>`
 
  display: flex;
  align-items: center;
  ${ResponsivePXValue('mobile', { mobile: 'flex-start', tablet: 'space-between', desktop: 'space-between' })}
  ${ResponsivePXValue('max-height', { mobile: '56px', tablet: '56px', desktop: '56px' })}
  ${ResponsivePXValue('gap', '8px')}

  ${(props): string => ResponsivePXValue('margin', { mobile: '0 0 6px', tablet: '0 0 6px', desktop: props.sliderKey === 0 ? '0 0 16px 0' : '18px 0 16px 0' })}

  .seperator {
    flex-grow: 1;
    position: revert;
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  }
  .filter-title {
    margin: 0;
    ${ResponsivePXValue('flex-shrink', { desktop: '0' })}
  }
  .see-all {
    flex-shrink: 0;
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  }
  
`

const Embla = styled.div`
  position: relative;
  ${ResponsivePXValue('overflow', { mobile: 'visible', tablet: 'hidden', desktop: 'hidden' })}
  width: 100%;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('gap', {
    mobile: '8px',
    tablet: '8px',
    desktop: '16px',
  })}
`

const EmblaSlide = styled.div`
  user-select: none;
  ${ResponsivePXValue('flex', { mobile: '0 0 157px', tablet: '0 0 157px', desktop: '0 0 218px' })}
`

const LeftControl = styled.div<{ visible: boolean, showCount: boolean }>`
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'block', desktop: 'block' })}
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  ${ResponsivePXValue('width', '28px')}
  ${ResponsivePXValue('height', '28px')}
  ${ResponsivePXValue('border-radius', '20px')}
  ${ResponsivePXValue('padding', '5px')}
  ${ResponsivePXValue('top', 'CALC(50% - 15px)')}
  left: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
 
`

const RightControl = styled.div<{ visible: boolean, showCount: boolean }>`
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'block', desktop: 'block' })}
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  ${ResponsivePXValue('width', '28px')}
  ${ResponsivePXValue('height', '28px')}
  ${ResponsivePXValue('border-radius', '20px')}
  ${ResponsivePXValue('padding', '5px')}
  ${ResponsivePXValue('top', 'CALC(50% - 15px)')}
  right: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
`

export interface ProductSliderProps {
  title?: string
  products: ProductListFragment[]
  total: number
  sliderKey?: number
  showCount?: boolean
  loading?: boolean
  basePath?: string
  onViewAll?: () => void
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  productsDiscount?: number | null
  forRecommendedProducts?: boolean
}

interface ProductSliderState {
  previousDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  disaplyButtons: boolean
}

const DEFAULT_STATE: ProductSliderState = {
  previousDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  disaplyButtons: false,
}

export function ProductSlider({ 
  title, 
  products, 
  total, 
  loading, 
  sliderKey = 0, 
  basePath = '', 
  onViewAll, 
  showCount = true, 
  overwriteBreadCrumbs, 
  productsDiscount = null }: ProductSliderProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<ProductSliderState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: !!(appData.app.isNativeApp),
    slidesToScroll: 1,
    containScroll: !(appData.app.isNativeApp) ? 'trimSnaps' : '',
    loop: false, 
  })

  const _handleGoLeft = (): void => {
    if (emblaApi.canScrollPrev()) {
      emblaApi.scrollPrev()
    }
  }

  const _handleGoRight = (): void => {
    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext()
    } else {
      emblaApi.scrollTo(0)
    }
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const previousDisabled = !emblaApi.canScrollPrev()
        const nextDisabled = false
        const currentIndex = emblaApi.selectedScrollSnap()
        setState((prevState) => update(prevState, {
          previousDisabled: { $set: previousDisabled },
          nextDisabled: { $set: nextDisabled },
          currentIndex: { $set: currentIndex },
        }))
      })
    }
  }, [emblaApi])

  let product: ProductListFragment

  return (
    <Container>
      <If condition={!!title || !!onViewAll}>
        <Header sliderKey={sliderKey}>
          <If condition={!!title}>
            <Heading variant='h5' className='filter-title' align='left'>
              {title}
            </Heading>
          </If>
          <If condition={showCount === true}>
            <Paragraph variant='p1' className='count' color={theme.colors.grey.silver}>({total})</Paragraph>
            <Seperator align='horizontal' className='seperator' color={theme.colors.grey.gallery} />
          </If>
          <If condition={!!onViewAll}>
            <Button
              disabled={loading}
              variant='ghost'
              title='SEE ALL'
              size='medium'
              className='see-all'
              onClick={onViewAll} />
          </If>
        </Header>
      </If>
      <Spacer>
        <Embla ref={emblaRef}>
          <EmblaContainer>
            <For each='product' of={products}>
              <EmblaSlide
                key={product.id}>
                <ProductCard basePath={basePath} key={product.id} product={product} disabled={loading} overwriteBreadCrumbs={overwriteBreadCrumbs} personalDiscount={productsDiscount ? productsDiscount : null}/>
              </EmblaSlide>
            </For>
          </EmblaContainer>
        </Embla>
        <LeftControl onClick={_handleGoLeft} visible={!state.previousDisabled} showCount={showCount} >
          <Icon
            icon={LocalIconEnums.CHEVRON_LEFT}
            color={theme.colors.green.bottleGreen} />
        </LeftControl>
        <RightControl onClick={_handleGoRight} visible={true} showCount={showCount} >
          <Icon
            icon={LocalIconEnums.CHEVRON_RIGHT}
            color={theme.colors.green.bottleGreen} />
        </RightControl>
      </Spacer>
      <If condition={!!onViewAll}>
        <DeviceContainer mobile tablet>
          <Button
            variant='ghost'
            title='SEE ALL'
            size='medium'
            className='mobile-all'
            fullWidth
            onClick={onViewAll} />
        </DeviceContainer>
      </If>
    </Container>
  )

}
