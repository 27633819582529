import { DocumentNode, FieldFunctionOptions, TypePolicies, gql } from '@apollo/client'

import { PaymentTokenFragment, PeachPaymentTokenFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'

export class PaymentTokenPlugin implements ConfigPlugin {

  typePolicies = (): TypePolicies => ({
    PaymentToken: {
      fields: {
        id: {
          read(obj: PaymentTokenFragment, options: FieldFunctionOptions): string {
            const publicHash = options.readField('publicHash') as string
            return publicHash
          },
        },
      },
    },
    PeachPaymentsToken: {
      fields: {
        id: {
          read(obj: PeachPaymentTokenFragment, options: FieldFunctionOptions): string {
            const paymentToken = options?.readField('paymentToken') as string
            return paymentToken
          },
        },
      },
    },
    PersonalDiscount: {
      fields: {
        id: {
          read(obj: PeachPaymentTokenFragment, options: FieldFunctionOptions): string {
            const paymentToken = options?.readField('code') as string
            return paymentToken
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend type PaymentToken {
      id: ID!
    }
    extend type PeachPaymentsToken {
      id: ID!
    }
  `

}
