import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductListImageFragment } from '@hooks/api'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '12px 32px 12px 16px', desktop: '12px 32px 12px 16px' })}

`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '88px', tablet: '94px', desktop: '94px' })}
  ${ResponsivePXValue('height', { mobile: '88px', tablet: '94px', desktop: '94px' })}
  ${ResponsivePXValue('margin', '0px 12px')}
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  flex-shrink: 0;

`

const InfoContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'column', desktop: 'column' })}
  justify-content: center;
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'flex-end', desktop: 'flex-end' })}
  ${ResponsivePXValue('align-items', { mobile: 'self-end', tablet: 'flex-end', desktop: 'flex-end' })}
  ${ResponsivePXValue('gap', '12px')}
  overflow-wrap: break-word;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '0px 6px' })}

  .title {
    ${ResponsivePXValue('flex-grow', { mobile: '1' })}
    ${ResponsivePXValue('width', { mobile: '100%' })}
  }
`

export interface OrderItemPanelProps {
  name: string
  quantity: number
  slug: string
  coverImage: ProductListImageFragment
  price: string
}

export function OrderItemPanel({ name, quantity, slug, coverImage, price }: OrderItemPanelProps): JSX.Element {
  const navigate = useNavigate()

  const _handleLink = (href: string): void => {
    navigate(`/${href}`)
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <Paragraph variant='p1' bold={true} className='title' onClick={() => _handleLink(slug)}>
          {name}
        </Paragraph>
        <Paragraph variant='p1'>
          Qty: {quantity}
        </Paragraph>
        <Paragraph variant='p1' bold={true}>
          {price}
        </Paragraph>
      </InfoContainer>
    </Container>
  )

}
