import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums, Paragraph, ResponsiveImage, Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { PageMenuCategoryChildFragment, PageMenuCategorySectionFragment, PageMenuImageCardFragment } from '@hooks/api'

const Container = styled.div`
  cursor: pointer;
  ${ResponsivePXValue('padding', '16px 32px')}
`

const TitleContainer = styled.div`
  ${ResponsivePXValue('padding', '16px 0')}
`

const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', '200px')}
  ${ResponsivePXValue('height', '200px')}
`

const CardImageBlock = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '140px')}
  flex-shrink: 0;
`

const CardTitleBlock = styled.div<{ backgroundColor: string, textColor: string }>`
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.textColor};
  ${ResponsivePXValue('height', '60px')}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '8px')}
`

const ChevronContainer = styled.div`
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

const IconBlock = styled.div`
  ${ResponsivePXValue('width', '117px')}
  ${ResponsivePXValue('height', '147px')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IconImageBlock = styled.div`
  ${ResponsivePXValue('width', '88px')}
  ${ResponsivePXValue('height', '88px')}

`

const IconTitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '8px 8px 8px 20px')}
`

export interface PageMenuCategorySectionProps {
  section: PageMenuCategorySectionFragment
}

export function PageMenuCategorySection({ section }: PageMenuCategorySectionProps): JSX.Element {

  const navigate = useNavigate()
  const theme = useTheme()

  const children = section.category.children.slice(0, 5)
  let child!: PageMenuCategoryChildFragment

  return (
    <Container>
      <TitleContainer>
        <Title variant='t3' color={section.titleColor}>
          {section.title}
        </Title>
      </TitleContainer>
      <Cards>
        <For each='child' of={children}>
          <CardBlock key={child.uid} onClick={() => navigate(child.canonicalUrl)}>
            <If condition={!!child.coverImage}>
              <CardImageBlock>
                <ResponsiveImage image={child.coverImage} />
              </CardImageBlock>
            </If>
            <CardTitleBlock backgroundColor={section.cardBackgroundColor} textColor={section.cardTextColor}>
              <Paragraph color={section.cardTextColor} bold>
                {child.name}
              </Paragraph>
              <ChevronContainer>
                <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={section.cardTextColor} />
              </ChevronContainer>
            </CardTitleBlock>
          </CardBlock>
        </For>
        <IconBlock>
          <IconImageBlock>
            <ResponsiveImage image={section.iconBlock.image} />
          </IconImageBlock>
          <IconTitleBlock>
            <Paragraph color={theme.colors.green.watercourse}>
              {section.iconBlock.title}
            </Paragraph>
            <ChevronContainer>
              <Icon icon={LocalIconEnums.CHEVRON_RIGHT} color={theme.colors.green.watercourse} />
            </ChevronContainer>
          </IconTitleBlock>
        </IconBlock>
      </Cards>
    </Container>
  )

}
