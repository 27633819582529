import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMyShopSectionFragment, ProductListFragment, useCustomerQuery, useGetAppQuery, useGetFrequentlyBoughtProductsLazyQuery, useGetFrequentlyBoughtProductsQuery } from '@hooks/api'
import { HTML, PageBanner } from '@molecules/content'
import { ProductSlider } from '@organisms/shop'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '32px')}
  ${ResponsivePXValue('border', '1px solid #000')}
`

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('gap', { mobile: '0', tablet: '16px', desktop: '16px' })}
  background-color: ${(props): string => props.backgroundColor};
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '', desktop: '' })}

  .block-title {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

  .block-paragraph {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

`

const TopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'space-between', desktop: 'space-between' })}
`

const TitleContainer = styled.div`
  .sub-title {
    ${ResponsivePXValue('margin-top', '12px')}
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface PageMyDiscountsSectionProps {
  section: PageMyShopSectionFragment
  showPlaceholder?: boolean
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  PRODUCTS = 'PRODUCTS',
}

interface PageMyShopSectionState {
  products: ProductListFragment[]
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: PageMyShopSectionState = {
  products: [],
  displayType: DisplayTypeEnum.LOADING,
}

export function PageMyShopSection({ section, showPlaceholder = false }: PageMyDiscountsSectionProps): JSX.Element {

  const [state, setState] = useState<PageMyShopSectionState>({ ...DEFAULT_STATE })
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [fetchMyShop] = useGetFrequentlyBoughtProductsLazyQuery()
  const navigate = useNavigate()

  const getMyShop = async (): Promise<void> => {
    const result = await fetchMyShop({ variables: { pageSize: section.productCount, currentPage: 1, period: 36 } })
    const products = result.data?.frequentlyBoughtProducts?.items?.map((myProd) => myProd.product)
    if (products.length) {
      setState((prevState) => update(prevState, {
        products: {
          $set: products,
        },
        displayType: {
          $set: DisplayTypeEnum.PRODUCTS,
        },
      }))
    } else {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    }
  }

  const _handleViewAll = () => {
    navigate('/my-ftn-shop/my-shop')
  }

  useEffect(() => {
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.LOADING,
        },
      }))
      getMyShop()
    } else if (!customerLoading) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    }
  }, [customerData])

  if (state.displayType === DisplayTypeEnum.EMPTY && !showPlaceholder && !section.placeholderBanner) {
    return null
  }

  return (
    <Container backgroundColor={section?.backgroundColor}>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LOADING}>
          <Loader />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && !!section.placeholderBanner}>
          <PageBanner pageBanner={section.placeholderBanner} />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && showPlaceholder}>
          <Placeholder>
            <Title variant='t3'>My Shop Placeholder</Title>
            <Paragraph>There is no preview content for this section while the user is logged out or has no previously bought items</Paragraph>
          </Placeholder>
        </When>
        <Otherwise>
          <TopContainer>
            <TitleContainer>
              <Title variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 't3' : 't1'} className='block-title' color={section.foregroundColor} >
                Previously Bought
              </Title>
            </TitleContainer>
            <DeviceContainer desktop tablet>
              <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium'/>
            </DeviceContainer>
          </TopContainer>
          <ProductSlider
            showCount={false}
            total={state.products?.length}
            products={state.products} />
          <DeviceContainer mobile>
            <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium' fullWidth/>
          </DeviceContainer>
        </Otherwise>
      </Choose>
    </Container>
  )
}
