import React, { useState, useEffect, useCallback } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment, PageBannerSectionFragment } from '@hooks/api/index'
import { PageBanner } from '@molecules/index'

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 0;

  .slider-slide {
    outline: none !important;
    cursor: default;
  }

  .previous-button {
    position: absolute;
    ${ResponsivePXValue('top', { mobile: '120px', tablet: '230px', desktop: '230px' })}
    ${ResponsivePXValue('left', { mobile: '10px', desktop: '30px' })}
  }

  .next-button {
    position: absolute;
    ${ResponsivePXValue('top', { mobile: '120px', tablet: '230px', desktop: '230px' })}
    ${ResponsivePXValue('right', { mobile: '10px', desktop: '30px' })}
  }
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
`

const EmblaSlide = styled.div`
  ${ResponsivePXValue('flex', '0 0 100%')}
`

export interface PageBannerSectionProps {
  section: PageBannerSectionFragment
}

interface PageBannerSectionState {
  previousDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  disaplyButtons: boolean
  autoScrolling: boolean
}

const DEFAULT_STATE: PageBannerSectionState = {
  previousDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  disaplyButtons: false,
  autoScrolling: true,
}

const AUTO_SCROLL_INTERVAL = 5000 

export function PageBannerSection({ section }: PageBannerSectionProps): JSX.Element {

  const [state, setState] = useState<PageBannerSectionState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const startAutoScroll = useCallback(() => {
    setState((prevState) => update(prevState, {
      autoScrolling: { $set: true },
    }))
  }, [])
  
  const stopAutoScroll = useCallback(() => {
    setState((prevState) => update(prevState, {
      autoScrolling: { $set: false },
    }))
  }, [])

  const _handlePrevious = useCallback(() => {
    if (emblaApi.canScrollPrev()) {
      stopAutoScroll()
      emblaApi.scrollPrev()
    }
    setTimeout(startAutoScroll, AUTO_SCROLL_INTERVAL)
  }, [emblaApi, stopAutoScroll, startAutoScroll])

  const _handleNext = useCallback(() => {
    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext()
    }
    setTimeout(startAutoScroll, AUTO_SCROLL_INTERVAL)
  }, [emblaApi, stopAutoScroll, startAutoScroll])

  useEffect(() => {
    if (!emblaApi) return

    const onSelect = () => {
      setState((prevState) => update(prevState, {
        previousDisabled: { $set: !emblaApi.canScrollPrev() },
        nextDisabled: { $set: !emblaApi.canScrollNext() },
        currentIndex: { $set: emblaApi.selectedScrollSnap() },
      }))
    }
    
    const onPointerDown = () => {
      stopAutoScroll()
    }

    const onPointerUp = () => {
      setTimeout(startAutoScroll, AUTO_SCROLL_INTERVAL)
    }
    
    let interValId: NodeJS.Timeout

    const runAutoScroll = () => {
      interValId = setInterval(() => {
        if (state.autoScrolling && emblaApi.canScrollNext()) {
          emblaApi.scrollNext()
        }
      }, AUTO_SCROLL_INTERVAL)
    }

    emblaApi.on('select', onSelect)
    emblaApi.on('pointerDown', onPointerDown)
    emblaApi.on('pointerUp', onPointerUp)

    runAutoScroll()

    return () => {
      clearInterval(interValId)
      emblaApi.off('select', onSelect)
      emblaApi.off('pointerDown', onPointerDown)
      emblaApi.off('pointerUp', onPointerUp)
    }

  }, [emblaApi, state.autoScrolling, stopAutoScroll, startAutoScroll])

  let banner: PageBannerFragment

  return (
    <Container>
      <Choose>
        <When condition={section?.banners?.length === 1}>
          <PageBanner
            key={section?.banners[0].id}
            pageBanner={section?.banners[0]} />
        </When>
        <Otherwise>
          <Embla ref={emblaRef}>
            <EmblaContainer>
              <For each='banner' of={section?.banners}>
                <EmblaSlide key={banner.id}>
                  <PageBanner
                    key={banner.id}
                    pageBanner={banner} />
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
        </Otherwise>
      </Choose>
      <If condition={section?.banners?.length > 1}>
        <Button
          variant='direction'
          shape='round'
          className='previous-button'
          disabled={state.previousDisabled}
          icon={LocalIconEnums.CHEVRON_LEFT}
          onClick={_handlePrevious} />
        <Button
          variant='direction'
          shape='round'
          className='next-button'
          disabled={state.nextDisabled}
          icon={LocalIconEnums.CHEVRON_RIGHT}
          onClick={_handleNext} />
      </If>
    </Container>
  )

}
