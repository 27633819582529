import React, { useState, useEffect } from 'react'

import { ApolloClient, NormalizedCache, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { useLocation } from 'react-router'
import styled from 'styled-components'

import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { Link, Icon, LocalIconEnums, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useCustomerQuery, useGetFrequentlyBoughtProductsQuery, useGetNavigationQuery, usePersonalDiscountsQuery } from '@hooks/api/index'
import { googleLogout } from '@react-oauth/google'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center; 
  
  ${ResponsivePXValue('width', '130px')}
  ${ResponsivePXValue('height', '72px')}

  .loader {
    ${ResponsivePXValue('width', '20px')}
  }
  .arrow {
    ${ResponsivePXValue('width', '20px')}
    ${ResponsivePXValue('margin', '10px 0')}
  }
`

const Profile = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  color: ${(props): string => props.theme.colors.green.greenVogue};
  font-family: open-sans;
  vertical-align: center;
  white-space: nowrap;
  font-weight: 700;
  ${ResponsivePXValue('font-size', '14px')}
`
const ProfileSpacer = styled.div`
  ${ResponsivePXValue('padding', '0 5px')}
  color: ${(props): string => props.theme.colors.grey.silver};
`

const ProfileButton = styled.div`
  :hover {
    text-decoration: underline;
  }
`

const NameWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: right;
  ${ResponsivePXValue('gap', '5px')}
`

const Inner = styled.div`
  ${ResponsivePXValue('padding', '8px 0')}
`

const InnerItem = styled.div`
  ${ResponsivePXValue('padding', '4px 8px')}
`

const ProfileDropdown = styled.div`
  position: absolute;
  box-shadow: 0px 0px 4px #ccc;
  z-index: 5;
  right: 0;
  cursor: pointer;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  color: ${(props): string => props.theme.colors.green.bottleGreen};
  ${ResponsivePXValue('top', '50px')}
  ${ResponsivePXValue('width', '230px')}

  .button {
    font-family: open-sans;
    display: block;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.green.bottleGreen};
    ${ResponsivePXValue('padding', '4px 8px')}
    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '20px')}
    ${ResponsivePXValue('border-radius', '2px')}
    &:hover {
      background-color: ${(props): string => props.theme.colors.green.watercourse};
      color: ${(props): string => props.theme.colors.white.pureWhite};
      font-weight: 600;
    }
  }

  .active {
    font-weight: 600;
  }
`

const Line = styled.div`
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.white.pampas};
  ${ResponsivePXValue('border-bottom-width', '2px')}
  `
export interface NavigationProfileProps {
  className?: string
}

export function NavigationProfile({ className }: NavigationProfileProps): JSX.Element {

  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()
  const { data: customerData, loading: customerLoading, refetch: refetchCustomer } = useCustomerQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const location = useLocation()
  const apolloClient: ApolloClient<NormalizedCacheObject> = useApolloClient() as ApolloClient<NormalizedCacheObject>

  const _handleLoginModal = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleSignUpModal = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
  }

  const _handleLogout = async (): Promise<void> => {
    googleLogout()
    AuthPlugin.shared().clear()
    await refetchCustomer()
    apolloClient.cache.evict({
      fieldName: 'personalDiscounts',
    })
    apolloClient.cache.evict({
      fieldName: 'frequentlyBoughtProducts',
    })
    refetchDiscounts()
    refetchMyShop()
  }

  const links = [
    [{
      title: 'Account',
      link: '/me/orders',
    }, {
      title: 'Orders',
      link: '/me/orders',
    }],
    [{
      title: 'Brands',
      link: '/brands',
    }, {
      title: 'Ingredient library',
      link: '/ingredient',
    },
    // removed refer a friend for desktop
    // {
    //   title: 'Refer a friend',
    //   link: '/me/Refer',
    // }
    ],
    [{
      title: 'Help',
      link: '/faq',
    }, {
      title: 'Logout',
      link: '',
    }],
  ]

  let index: number
  let link: { title: string, link: string }
  let linkCollection: []

  return (
    <ProfileWrapper
      className={className}
      onMouseEnter={(): void => NavigationPlugin.shared().openUserMenu()}
      onMouseLeave={(): void => NavigationPlugin.shared().closeUserMenu()}>
      <Choose>
        <When condition={customerLoading}>
          <Skeleton>
            <SkeletonNode
              color='pampas'
              height={{ mobile: '0', tablet: '10px', desktop: '20px' }}
              width={{ mobile: '0', tablet: '120px', desktop: '120px' }}
              shape='rounded'/>
          </Skeleton>
        </When>
        <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
          <NameWrapper >
            <Profile>
              {customerData.currentCustomer.firstname}
            </Profile>
            <Icon className="arrow" icon={navigationData.navigation.showUserMenu ? LocalIconEnums.CHEVRON_UP : LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue} />
            <If condition={navigationData.navigation.showUserMenu}>
              <ProfileDropdown>

                <For each='linkCollection' of={links} index='index'>
                  <Inner>
                    <For each='link' of={linkCollection} index='index'>
                      <Choose>
                        <When condition={!!link.link}>
                          <InnerItem key={index}>
                            <Link className={(location.pathname === link.link ? 'active' : '') + ' button'} href={link.link} >{link.title}</Link>
                          </InnerItem>
                        </When>
                        <Otherwise>
                          <InnerItem key={index}>
                            <Link href='' className={(location.pathname === link.link ? 'active' : '') + ' button'} onClick={_handleLogout} >{link.title}</Link>
                          </InnerItem>
                        </Otherwise>
                      </Choose>
                    </For>
                  </Inner>
                  <If condition={index < links.length} >
                    <Line />
                  </If>
                </For>

              </ProfileDropdown>
            </If>
          </NameWrapper>

        </When>
        <Otherwise>
          <Profile >
            <ProfileButton onClick={(): void => _handleLoginModal()} >LOGIN</ProfileButton>
            <ProfileSpacer>|</ProfileSpacer>
            <ProfileButton onClick={(): void => _handleSignUpModal()} >SIGN UP</ProfileButton>
          </Profile>
        </Otherwise>
      </Choose>

    </ProfileWrapper >
  )

}
