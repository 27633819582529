import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { OrderItemFragment } from '@hooks/api/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '180px', tablet: '220px', desktop: '220px' })}
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
`

const CardLink = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`
const ImageContainer = styled.div`
  flex-basis: 100%;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('height', { mobile: '125px', tablet: '150px', desktop: '150px' })}
  ${ResponsivePXValue('padding', { mobile: '9px 0 3px 0', tablet: '9px 0', desktop: '18px 0' })}

`

const TitleContainer = styled.div`
  ${ResponsivePXValue('padding', '12px')}
  font-family: open-sans;
  font-weight: 600;
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '14px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${ResponsivePXValue('-webkit-line-clamp', { mobile: '2', tablet: '3', desktop: '3' })}
  ${ResponsivePXValue('padding-bottom', '2px')}
  margin: 0;
`

export interface OrderProductCardProps {
  product: OrderItemFragment
  disabled?: boolean
}

export function OrderProductCard({ product }: OrderProductCardProps): JSX.Element {

  const navigate = useNavigate()

  const openDetails = (): void => {
    navigate(`/${product.productUrlKey}`)
  }

  const _handleLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.preventDefault()
    openDetails()
  }

  return (
    <Container>
      <CardLink onClick={_handleLink}>
        <ImageContainer>
          <ResponsiveImage image={product?.coverImage} objectFit='contain' />
        </ImageContainer>
        <TitleContainer>
          {product.productName}
        </TitleContainer>
      </CardLink>
    </Container>
  )

}
