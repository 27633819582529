import React, { useState, useEffect } from 'react'

import { sentenceCase } from 'change-case'
import parsePhoneNumberFromString from 'libphonenumber-js'
import update from 'react-addons-update'
import styled from 'styled-components'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

import { APP_DEFAULT_STATE } from '@api/local'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { CustomerAddressFragment, useCreateCustomerAddressMutation, useUpdateCustomerAddressMutation, useGetRegionsQuery, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { RadioInput, TextInput, SelectInput, CheckBoxInput, TextAreaInput, ItemContainer, Container as RadioContainer, TextAreaInputElement } from '@molecules/inputs'
import { AddressViewMode } from '@organisms/user'
import { useSimpleToasts } from '@simple/toasts'
import { AddressGroupEnum, CountryCodeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { Form, useForm } from '../inputs/Form'

const Container = styled.div`
  .input {
    ${ResponsivePXValue('margin-bottom', '16px')}
    
  }

  .button {
    ${ResponsivePXValue('margin', '20px 0')}
  }
  .checkbox{
    ${ResponsivePXValue('padding', { mobile: '20px 0' })}
  }
  .mobileInput {

    ${ResponsivePXValue('gap', '16px')}

    & > ${ItemContainer} {
      ${ResponsivePXValue('padding', '8px 10px')}
      flex: 1;
      justify-content: start;
    }
      
    & > ${ItemContainer}[data-selected="false"] {
      border: solid 1px ${({ theme }) => theme.colors.grey.silver};
    }

    & > ${ItemContainer}[data-selected="true"] {
      background-color: ${({ theme }) => theme.colors.green.swansDown};
    }


  }
`

const FixedContainer = styled.div<{viewMode: AddressViewMode, isMobile: boolean}>`
  position: fixed;
  ${props => props.viewMode === AddressViewMode.CHECKOUT ? ResponsivePXValue('bottom', '0') : ResponsivePXValue('bottom', (props.isMobile) ? '70px' : '55px')};
  left: 0;
  ${props => props.isMobile && props.viewMode === AddressViewMode.CHECKOUT ? ResponsivePXValue('padding', { mobile: '12px 16px 50px 16px' }) : ResponsivePXValue('padding', { mobile: '12px 16px' })};
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', '56px')}
  ${ResponsivePXValue('z-index', '10')}
  ${ResponsivePXValue('box-shadow', '0px -2px 6px rgba(0, 0, 0, 0.05)')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const PowerGoogle = styled.div`
  display: flex;
  justify-content: flex-end;
  ${ResponsivePXValue('margin-top', '-12px')}
  ${ResponsivePXValue('height', '15px')}
  .icon {
    ${ResponsivePXValue('width', '40px')}
  }
`

const FlexContainer = styled.div`
  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'flex', desktop: 'flex' })}

  ${ResponsivePXValue('gap', '16px')}
  .flex-item {
    ${ResponsivePXValue('width', { mobile: '100%', tablet: 'CALC(50% - 8px)', desktop: 'CALC(50% - 8px)' })}
  }
`

const GoogleLogo = styled.img.attrs({ src: '/images/google_on_white.png' })`
  width: 100%;
  ${ResponsivePXValue('width', '35px')}
  object-fit: contain;
`

interface AddressData {
  addressGroup: AddressGroupEnum
  company?: string
  deliveryNote?: string
  firstname: string
  lastname: string
  telephone: string
  telephoneAlt?: string
  street: string
  suburb: string
  city: string
  region: string
  countryId: CountryCodeEnum
  postcode: string
  defaultShipping: boolean
  defaultBilling: boolean | string[]
  placeId?: string
}

const parseGeoData = (geoData: google.maps.GeocoderResult): Partial<AddressData> => {
  const regionMap: { [k: string]: string } = {
    EC: 'ZA-EC',
    FS: 'ZA-FS',
    GT: 'ZA-GT',
    GP: 'ZA-GT',
    NL: 'ZA-NL',
    KZN: 'ZA-NL',
    LP: 'ZA-LP',
    MP: 'ZA-MP',
    NC: 'ZA-NC',
    NW: 'ZA-NW',
    WC: 'ZA-WC',
  }
  const streetNumberPart = geoData.address_components.find((component) => component.types.includes('street_number'))
  const streetNamePart = geoData.address_components.find((component) => component.types.includes('route'))
  let suburbPart = geoData.address_components.find((component) => component.types.includes('sublocality_level_2'))
  if (!suburbPart) {
    suburbPart = geoData.address_components.find((component) => component.types.includes('locality'))
  }
  const cityPart = geoData.address_components.find((component) => component.types.includes('administrative_area_level_2'))
  const regionPart = geoData.address_components.find((component) => component.types.includes('administrative_area_level_1'))
  const postalCodePart = geoData.address_components.find((component) => component.types.includes('postal_code'))
  const streetArray = []
  if (streetNumberPart) {
    streetArray.push(streetNumberPart.short_name)
  }
  if (streetNamePart) {
    streetArray.push(streetNamePart.short_name)
  }
  const street = streetArray.join(' ')
  const suburb = suburbPart?.short_name || ''
  let city = cityPart?.short_name || ''
  if (city.match(/City of (.*?) Metropolitan Municipality/)) {
    city = city.replace(/City of (.*?) Metropolitan Municipality/, '$1')
  }
  city = sentenceCase(city)
  const region = regionPart?.short_name && regionMap?.[regionPart.short_name] ? regionMap?.[regionPart.short_name] : ''
  const postcode = postalCodePart?.short_name || ''
  const result: Partial<AddressData> = {
    street,
    suburb,
    city,
    region,
    postcode,
  }
  return result
}

export interface AddressFormProps {
  viewMode?: AddressViewMode
  address?: CustomerAddressFragment
  onSuccess: (isNew: boolean, address: CustomerAddressFragment) => void
  onCancel: () => void
  handleSelect: (id: number) => void
}
interface AddressFormState {
  loadingAddress: boolean
  placeId: string | null
  isBusiness: boolean
}

const DEFAULT_STATE: AddressFormState = {
  loadingAddress: false,
  placeId: null,
  isBusiness: false,
}

export function AddressForm({ onSuccess, onCancel, address, handleSelect, viewMode = AddressViewMode.CHECKOUT }: AddressFormProps): JSX.Element {

  const { data: provicesData, loading: provincesLoading } = useGetRegionsQuery()

  const [createCustomerAddress, { loading: createAddressLoading }] = useCreateCustomerAddressMutation()
  const [updateCustomerAddress, { loading: updateAddressLoading }] = useUpdateCustomerAddressMutation()
  const [state, setState] = useState<AddressFormState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.isNativeApp

  const {
    ready,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'ZA',
      },
      types: ['address'],
    },
    debounce: 500,
  })
  const form = useForm()

  const geoCodeAddress = async (): Promise<void> => {
    if (state.placeId) {
      setState((prevState) => update(prevState, {
        loadingAddress: {
          $set: true,
        },
      }))
      const response = await getGeocode({ placeId: state.placeId })
      const addressData = parseGeoData(response[0])
      form.setFieldsValue(addressData)
      setState((prevState) => update(prevState, {
        loadingAddress: {
          $set: false,
        },
      }))
    }
  }

  const _handleSuccess = async (data: AddressData): Promise<void> => {

    delete data.placeId
    data.defaultBilling = ((data?.defaultBilling as string[]) || []).includes('YES')
    const region = provicesData.country.availableRegions.find((region) => region.code === data.region)

    if (data.telephoneAlt === null) {
      data.telephoneAlt = ''
    }

    if (data.deliveryNote === null) {
      data.deliveryNote = ''
    }

    if (address) {
      try {
        const mutationResponse = await updateCustomerAddress({
          variables: {
            id: address.id,
            input: {
              ...data,
              region: {
                region: region.name,
                regionId: region.id,
                regionCode: region.code,
              },
            },
          },
        })
        addToast({
          message: 'Address successfully updated',
          appearance: 'success',
        })

        onSuccess(false, mutationResponse.data.address)
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    } else {
      try {
        const mutationResponse = await createCustomerAddress({
          variables: {
            input: {
              ...data,
              // newest get set to default
              defaultShipping: true,
              region: {
                region: region.name,
                regionId: region.id,
                regionCode: region.code,
              },
              countryCode: 'ZA',
            },
          },
        })

        addToast({
          message: 'Address successfully created',
          appearance: 'success',
        })
        onSuccess(false, mutationResponse.data.address)
        handleSelect(mutationResponse.data.address.id)
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }
  }

  const _handleChange = (_: unknown, data: { placeId: string, addressGroup: string }): void => {
    if (data.placeId !== state.placeId) {
      setState((prevState) => update(prevState, {
        placeId: {
          $set: data.placeId,
        },
      }))
    }

    setState((prevState) => update(prevState, {
      isBusiness: {
        $set: data.addressGroup === AddressGroupEnum.BUSINESS,
      },
    }))
  }

  const _handleSearchChange = (search: string): void => {
    setValue(search)
  }

  useEffect((): void => {
    geoCodeAddress()
  }, [state.placeId])

  useEffect((): void => {
    if (address) {
      form.setFieldsValue({
        ...address,
        region: address.region.regionCode,
        defaultBilling: address.defaultBilling ? ['YES'] : [],
      })

      setState((prevState) => update(prevState, {
        isBusiness: {
          $set: address.addressGroup === AddressGroupEnum.BUSINESS,
        },
      }))

      if (address.telephone) {
        const regExp = /^0[0-9].*$/
        if (regExp.test(address.telephone)) {
          let newNumber = null
          const values = address.telephone.split(' ')
          const prefix = SiteHelper.getCountryCodeForTelPrefix(values.shift())
          const phoneNumber = parsePhoneNumberFromString(address.telephone, prefix)
          newNumber = phoneNumber.formatInternational()
          form.setFieldsValue({
            telephone: newNumber,
          })
        }
      }

    }

  }, [address])

  const places = data.map((location) => ({ title: location.description, value: location.place_id })) as { title: string, value: string }[]
  const provinces: { title: string, value: string }[] = provicesData?.country?.availableRegions?.map((region) => ({
    title: region.name,
    value: region.code,
  })) || []

  const addressGroups = [{
    title: 'Residential',
    value: AddressGroupEnum.RESIDENTIAL,
  }, {
    title: 'Business',
    value: AddressGroupEnum.BUSINESS,
  }]

  const addressOptions = [{
    title: 'Make this my billing address',
    value: 'YES',
  }]

  return (
    <Container>
      <Form form={form} onFinish={_handleSuccess} onValuesChange={_handleChange} loading={createAddressLoading || updateAddressLoading} initialValues={{ addressGroup: AddressGroupEnum.RESIDENTIAL }}>
        <DeviceContainer mobile>
          <RadioInput
            name='addressGroup'
            variant='horizontal'
            options={addressGroups}
            wrapperClassName='flex-item input'
            className='mobileInput'
            rules={[{ required: true, message: 'Please select Business or Residential' }]} />
        </DeviceContainer>
        <DeviceContainer desktop tablet>
          <RadioInput
            name='addressGroup'
            variant='horizontal'
            options={addressGroups}
            wrapperClassName='input'
            rules={[{ required: true, message: 'Please select Business or Residential' }]} />
        </DeviceContainer>

        <If condition={state.isBusiness}>
          <TextInput
            name='company'
            placeholder='Business Name'
            label='Business Name'
            wrapperClassName='input'
            className='textInput'
            rules={[{ required: true, message: 'Please enter a valid business name', pattern: /^[a-zA-Z\s-]+$/ }]} />
        </If>
        <FlexContainer>
          <TextInput
            name='firstname'
            label='Recipient First Name'
            placeholder='Recipient First Name'
            wrapperClassName='flex-item input'
            className='textInput'
            rules={[{ required: true, message: 'Please enter a valid first name', pattern: /^[a-zA-Z\s-]+$/ }]} />
          <TextInput
            name='lastname'
            label='Recipient Last Name'
            placeholder='Recipient Last Name'
            wrapperClassName='flex-item input'
            className='textInput'
            rules={[{ required: true, message: 'Please enter a valid last name', pattern: /^[a-zA-Z\s-]+$/ }]}/>
        </FlexContainer>
        <DeviceContainer mobile>
          <FlexContainer>
            <TextInput
              name='telephone'
              label='Contact Number'
              variant='phone'
              placeholder='Contact Number'
              wrapperClassName='input'
              className='textInput'
              rules={[{ required: true, message: 'Please enter a contact number' }]} />
            <TextInput
              name='telephoneAlt'
              label='Alternative Contact'
              variant='phone'
              placeholder='Alternative Contact'
              wrapperClassName='input'
              className='textInput'
              rules={[{ required: false, message: 'Please enter a valid alternative contact number' }]} />
          </FlexContainer>
        </DeviceContainer>
        <DeviceContainer desktop tablet>
          <TextInput
            name='telephone'
            label='Contact Number'
            variant='phone'
            placeholder='Contact Number'
            wrapperClassName='input'
            className='textInput'
            rules={[{ required: true, message: 'Please enter a contact number' }]} />
          <TextInput
            name='telephoneAlt'
            label='Alternative Contact Number'
            variant='phone'
            placeholder='Alternative Contact Number'
            wrapperClassName='input'
            className='textInput'
            rules={[{ required: false, message: 'Please enter a valid alternative contact number' }]} />
        </DeviceContainer>
        <SelectInput
          name='placeId'
          label='Address'
          placeholder={address ? address.street[0] + ', ' + address.suburb + ', ' + address.region.region : 'Address'}
          loading={!ready}
          onSearchChange={_handleSearchChange}
          options={places}
          wrapperClassName='input'
          className='textInput'
          noOptionsMsg='Enter street address'/>
        <PowerGoogle>
          <GoogleLogo />
        </PowerGoogle>

        <TextInput
          name='street'
          label='Street Name'
          placeholder='Street Name'
          wrapperClassName='input'
          className='textInput'
          loading={state.loadingAddress}
          rules={[{ required: true, message: 'Please enter a street name and number' }]} />

        <DeviceContainer mobile>
          <FlexContainer>
            <TextInput
              name='suburb'
              label='Suburb'
              placeholder='Suburb'
              wrapperClassName='input'
              className='textInput'
              loading={state.loadingAddress}
              rules={[{ required: true, message: 'Please enter a suburb' }]} />
            <TextInput
              name='city'
              label='City'
              placeholder='City'
              wrapperClassName='input'
              className='textInput'
              loading={state.loadingAddress}
              rules={[{ required: true, message: 'Please enter a city' }]} />
          </FlexContainer>
          <FlexContainer>
            <SelectInput
              name='region'
              label='Province'
              options={provinces}
              placeholder='Province'
              loading={provincesLoading || state.loadingAddress}
              wrapperClassName='input'
              className='textInput'
              rules={[{ required: true, message: 'Please select a province' }]} />
            <TextInput
              name='postcode'
              label='Postal Code'
              placeholder='Postal Code'
              wrapperClassName='input'
              className='textInput'
              loading={state.loadingAddress}
              rules={[{ required: true, message: 'Please enter a postal code' }]} />
          </FlexContainer>
        </DeviceContainer>
        <DeviceContainer desktop tablet>
          <TextInput
            name='suburb'
            label='Suburb'
            placeholder='Suburb'
            wrapperClassName='input'
            className='textInput'
            loading={state.loadingAddress}
            rules={[{ required: true, message: 'Please enter a suburb' }]} />
          <TextInput
            name='city'
            label='City'
            placeholder='City'
            wrapperClassName='input'
            className='textInput'
            loading={state.loadingAddress}
            rules={[{ required: true, message: 'Please enter a city' }]} />
          <SelectInput
            name='region'
            label='Province'
            options={provinces}
            placeholder='Province'
            loading={provincesLoading || state.loadingAddress}
            wrapperClassName='input'
            className='textInput'
            rules={[{ required: true, message: 'Please select a province' }]} />
          <TextInput
            name='postcode'
            label='Postal Code'
            placeholder='Postal Code'
            wrapperClassName='input'
            className='textInput'
            loading={state.loadingAddress}
            rules={[{ required: true, message: 'Please enter a postal code' }]} />
        </DeviceContainer>

        <TextAreaInput
          name='deliveryNote'
          placeholder='Delivery Note'
          label='Delivery Note'
          wrapperClassName='input'
          className='textInput'
          loading={state.loadingAddress} />
        <CheckBoxInput
          className='checkbox'
          name='defaultBilling'
          options={addressOptions}
          loading={state.loadingAddress} />
        <DeviceContainer mobile>
          <FixedContainer viewMode={viewMode} isMobile={isMobile}>
            <Button fullWidth title='SAVE ADDRESS' variant='primary' onClick={form.submit} />
          </FixedContainer>
        </DeviceContainer>
        <DeviceContainer desktop tablet>
          <FlexContainer>
            <Button title='CANCEL' variant='ghost' onClick={onCancel} className='button' />
            <Button title='SAVE ADDRESS' variant='primary' onClick={form.submit} className='button' />
          </FlexContainer>
        </DeviceContainer>

      </Form>

    </Container>
  )

}
