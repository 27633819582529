import React, { useEffect } from 'react'

import qs from 'qs'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { CacheManagementAsync } from '@components/builder/pages/CacheManagementAsync'
import { PageBuilderPageAsync } from '@components/builder/pages/PageBuilderPageAsync'
import { PageBuilderPageRendererAsync } from '@components/builder/pages/PageBuilderPageRendererAsync'
import { PageBuilderPagesAsync } from '@components/builder/pages/PageBuilderPagesAsync'
import { WidgetsAsync } from '@components/builder/pages/WidgetsAsync'
import { ActiveWidgetsDocument, CategoryFragmentDoc, GetCategoryChildrenDocument, GetCategoryMenuQueryDocument, PageFragmentDoc, ProductListFragmentDoc, useActiveWidgetsChangesSubscription, useCategoryChangeSubscription, useCategoryTreeChangeSubscription, useClientRefreshSubscription, useContentChangeSubscription, useCustomerQuery, useProductChangeSubscription } from '@hooks/api'
import { useGetAppQuery } from '@hooks/api/index'
import { PageFallbackComponent } from '@organisms/content'
import { LoginAsCustomer } from '@pages/LoginAsCustomer'
import { Maintenance } from '@pages/Maintenance'
import { PageRenderer } from '@pages/PageRenderer'
import { SectionRenderer } from '@pages/SectionRenderer'
import { SearchAsync, GiftVouchersAsync, BrandsAsync, CartAsync, CheckoutAsync, MeAsync, GiftVoucherAsync, IngredientsLibraryAsync, ContactUsAsync, MyFtnShopAsync } from '@routes/index'
import { Default } from '@templates/index'
import { ClientReloadTypeEnum } from '@uctypes/api/globalTypes'

import { CategoryWrapperAsync } from './CategoryWrapperAsync'
import { Magento } from './Magento'
import { ProductWrapperAsync } from './ProductWrapperAsync'

export function Routes(): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: customerData } = useCustomerQuery()

  useEffect(() => {
    if (window !== undefined && customerData?.currentCustomer) {
      window.piiData = {
        customerId: customerData?.currentCustomer?.magentoId,
        firstName: customerData?.currentCustomer?.firstname,
        email: customerData?.currentCustomer?.email,
        mobile: customerData?.currentCustomer?.mobile,
      }
      const event = new CustomEvent('pii:loaded')
      window.dispatchEvent(event)
    }
  }, [customerData?.currentCustomer])

  useProductChangeSubscription({
    onData: ({ client, data }) => {
      const id = client.cache.identify(data.data?.productChange)
      if (id) {
        client.writeFragment({
          fragmentName: 'ProductListFragment',
          fragment: ProductListFragmentDoc,
          data: {
            ...data.data.productChange,
          },
          id,
        })
      }
    },
  })

  useCategoryChangeSubscription({
    onData: ({ client, data }) => {
      const id = client.cache.identify(data.data?.categoryChange)
      if (id) {
        client.writeFragment({
          fragmentName: 'CategoryFragment',
          fragment: CategoryFragmentDoc,
          data: {
            ...data.data.categoryChange,
          },
          id,
        })
      }
    },
  })

  useCategoryTreeChangeSubscription({
    onData: ({ client, data }) => {
      const id = client.cache.identify(data.data?.categoryTreeChange)
      if (id) {
        client.writeFragment({
          fragmentName: 'CategoryFragment',
          fragment: CategoryFragmentDoc,
          data: {
            ...data.data.categoryTreeChange,
          },
          id,
        })
      }
      client.refetchQueries({
        include: [GetCategoryMenuQueryDocument, GetCategoryChildrenDocument],
      })
    },
  })

  useActiveWidgetsChangesSubscription({
    onData: ({ client }) => {
      client.refetchQueries({
        include: [ActiveWidgetsDocument],
      })
    },
  })

  useContentChangeSubscription({
    onData: ({ client, data }) => {
      const id = client.cache.identify(data.data?.pageChange)
      if (id) {
        client.writeFragment({
          fragmentName: 'PageFragment',
          fragment: PageFragmentDoc,
          data: {
            ...data.data.pageChange,
          },
          id,
        })
      }
    },
  })

  useClientRefreshSubscription({
    onData: ({ client, data }) => {
      if (data.data.clientRefresh === ClientReloadTypeEnum.DATA_ONLY) {
        client.reFetchObservableQueries()
      } else {
        window.location.reload()
      }
    },
  })

  return (
    <Choose>
      <When condition={appData.app.magentoIsInMaintenace}>
        <Maintenance />
      </When>
      <Otherwise>
        <ErrorBoundary FallbackComponent={PageFallbackComponent}>
          <RouterRoutes>
            {/* Cart */}
            <Route element={<Default><CartAsync /></Default>} path='/cart' />

            {/* Checkout */}
            <Route element={<Default><CheckoutAsync /></Default>} path='/checkout' />
            <Route element={<Default><CheckoutAsync /></Default>} path='/checkout/:step' />
            <Route element={<Default><CheckoutAsync /></Default>} path='/checkout/:step/:id' />

            {/* Category Lines */}
            <Route element={<Default><SearchAsync /></Default>} path='/search' />

            {/* Content */}
            <Route element={<Default><BrandsAsync /></Default>} path='/brands' />
            <Route element={<Default><IngredientsLibraryAsync /></Default>} path='/ingredient' />
            <Route element={<Default><ContactUsAsync /></Default>} path='/contact-us' />

            {/* User Specific / Non indexed */}
            <Route element={<Default><MeAsync /></Default>} path='/me/*' />
            <Route element={<Default><MyFtnShopAsync /></Default>} path='/my-ftn-shop/*' />
            <Route element={<Default><GiftVouchersAsync /></Default>} path='/gift-vouchers' />
            <Route element={<Default><GiftVoucherAsync /></Default>} path='/gift-voucher/:slug' />
            <Route element={<LoginAsCustomer />} path='/loginascustomer/login/index' />

            {/* Utility */}
            <Route element={<SectionRenderer />} path='/section/:id' />
            <Route element={<PageRenderer />} path='/page/:id' />
            <Route element={<PageBuilderPagesAsync />} path='/page-builder' />
            <Route element={<PageBuilderPageAsync />} path='/page-builder/:pageId/:contentId' />
            <Route element={<WidgetsAsync />} path='/widget-management' />
            <Route element={<CacheManagementAsync />} path='/cache-management' />
            <Route element={<PageBuilderPageRendererAsync />} path='/page-builder-renderer/:pageId/:contentId' />
            <Route element={<LoginAsCustomer />} path='/loginascustomer/login/index' />

            <Route element={<Default><ProductWrapperAsync /></Default>} path='/catalog/product/view/id/:productId' />
            <Route element={<Default><CategoryWrapperAsync /></Default>} path='/catalog/product/view/id/:productId/category/:categoryId' />
            <Route element={<Default><CategoryWrapperAsync /></Default>} path='/catalog/view/id/:categoryId' />
            <Route path="/customer/account/createPassword/_use_frontend_url/:id/" element={<Navigate replace to={`/?password-reset-token=${qs.parse(location.search.replace('?', '')).token}`} />} />

            <Route path='*' element={<Default><Magento /></Default>} />
            <Route element={<Default><Magento /></Default>} />
          </RouterRoutes>
        </ErrorBoundary>
      </Otherwise>
    </Choose>

  )
}
