import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { useNavigate } from 'react-router'

const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
`

const ImageContainer = styled.div`
  aspect-ratio : 1 / 1;
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('margin-bottom', '16px')}
  flex-shrink: 0;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  .block-title {
    max-width: 100%;
    white-space: pre-wrap;
    font-family: fortescue;
  }

`

export interface PageImageBlockProps {
  block: PageBlockFragment
}

export function PageImageBlock({ block }: PageImageBlockProps): JSX.Element {
  const { href, image, foregroundColor, title } = block
  const navigate = useNavigate()

  const _handleLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(href)
  }
  return (
    <Container
      href={href || '#'} onClick={_handleLink}>
      <ImageContainer>
        <ResponsiveImage image={image} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <Title variant='t5' align='center' className='block-title' color={foregroundColor}>{title}</Title>
      </ContentContainer>
    </Container>
  )

}
