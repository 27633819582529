import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsiveImage } from '@components/atoms'
import { ResponsivePXValue } from '@components/Theme'
import { ProductSearchFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('min-height', '42px')}
  ${ResponsivePXValue('margin', '2px 0 0 0')}
  width: 100%;
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', '50px')}
  ${ResponsivePXValue('height', '40px')}
`

const ContentContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  ${ResponsivePXValue('padding', '6px')}

  white-space: normal;

  .title {
    color: ${(props): string => props.theme.colors.black.pureBlack};
  }

`

export interface ProductSearchItemProps {
  item: ProductSearchFragment
  onSelect?: (path: string) => void
}

export function ProductSearchItem({ item, onSelect }: ProductSearchItemProps): JSX.Element {

  const _handlClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    onSelect?.(`/${item.canonicalUrl}`)
  }

  let price = SiteHelper.formatCurrency(item.priceRange.minimumPrice.finalPrice)
  if (item.priceRange.maximumPrice.finalPrice.value !== item.priceRange.minimumPrice.finalPrice.value) {
    price = `${SiteHelper.formatCurrency(item.priceRange.minimumPrice.finalPrice)}`
  }

  return (
    <Container href={`/${item.canonicalUrl}`} onClick={_handlClick}>

      <ImageContainer>
        <ResponsiveImage image={item.image} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <Paragraph variant='p2' >{item.name}</Paragraph>
        <Paragraph variant='p1' bold >{price}</Paragraph>
      </ContentContainer>

    </Container>
  )

}
