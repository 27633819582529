import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.a<{ hovering: boolean, backgroundColor: string }>`
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  ${ResponsivePXValue('gap', '95px')}
  width: 100%;

  .nav-item {
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
`

export interface DesktopCategoryBarItemProps {
  title: string
  href: string
  active?: boolean
  hovering?: boolean
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function DesktopCategoryBarItem({ title, href, active = false, hovering = false, onClick, onMouseEnter, onMouseLeave }: DesktopCategoryBarItemProps): JSX.Element {

  const theme = useTheme()

  const _handleMouseEnter = () => {
    onMouseEnter?.()
  }

  const _handleMouseLeave = () => {
    onMouseLeave?.()
  }

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onClick?.()
  }

  const foregroundColor = active ? theme.colors.green.greenVogue : theme.colors.green.greenVogue
  const backgroundColor = active ? theme.colors.white.pureWhite : theme.colors.white.pureWhite

  return (
    <Container
      href={href}
      hovering={hovering}
      backgroundColor={backgroundColor}
      onMouseEnter={_handleMouseEnter}
      onMouseLeave={_handleMouseLeave}
      onClick={_handleClick}>
      <Paragraph
        className='nav-item'
        align='center'
        bold={hovering}
        decoration={hovering ? 'underline' : undefined}
        variant='p1'
        color={foregroundColor}>
        {title}
      </Paragraph>
    </Container>
  )

}
