import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import update from 'react-addons-update'
import ReactDOM from 'react-dom'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api/index'
import { useClickOutside } from '@hooks/UseClickOutside'

import { Chip } from './Chip'
import { FormContext, FormContextProperties, FormValue } from './Form'
import { InputWrapper } from './InputWrapper'

interface Values {
  value?: FormValue | FormValue[]
  onChange?: (value: FormValue | FormValue[]) => void
}

export interface SelectOption {
  title: string
  value: FormValue
  disabled?: boolean
}

interface SelectInputInnerProps extends SelectFormInputProps {
  control: Values
  meta: Meta
  form: FormInstance
}
interface SelectDisplayElementInterface {
  error: boolean
  disabled: boolean
  focused: boolean
  isPlaceholder: boolean
  border: boolean
}

const Container = styled.div`
  position: relative;
`

const SelectDisplayElement = styled.div<SelectDisplayElementInterface>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-style: solid;
  border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  width: 100%;
          

  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '2px', desktop: '2px' })};
  ${ResponsivePXValue('font-weight', { mobile: '100', tablet: '400', desktop: '400' })};
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '12px' })};
  ${ResponsivePXValue('height', { mobile: '33px', tablet: '36px', desktop: '36px' })};
  ${ResponsivePXValue('padding', { mobile: '0 8px', tablet: '0 8px', desktop: '8px 12px' })};

  ${(props): CSS => {
    if (props.border) {
      return css`
          ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '1px', desktop: '1px' })};
      `
    }
  }}

  ${(props): CSS => {
    if (props.focused) {
      return css`
        border-color: ${(props.error) ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
        ${LiteBoxShadow};
      `
    }

    if (props.isPlaceholder) {
      return css`
        color: ${props.theme.colors.grey.stormDust};
      `
    }

    if (props.disabled) {
      return css`
        border-color: ${props.theme.colors.grey.silver};
        color: ${props.theme.colors.grey.silver};
        background-color: ${props.theme.colors.grey.athens};
      `
    }
  }}

`

const SelectInputElement = styled.input.attrs({ automcomplete: 'no' }) <{ error: boolean, disabled: boolean }>`
  font-family: open-sans;
  border-style: none;
  width: 100%;
  color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.stormDust};
  background-color: ${(props): string => props.theme.colors.white.pureWhite};

  ${ResponsivePXValue('font-weight', { mobile: '400', tablet: '400', desktop: '400' })};
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '12px', desktop: '12px' })};
  ${ResponsivePXValue('padding', '0')}

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.green.watercourse};
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.grey.stormDust};
    opacity: 1;
  }


 ${(props): CSS => {
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.grey.silver};
        color: ${(props): string => props.theme.colors.grey.silver};
        background-color: ${(props): string => props.theme.colors.grey.athens};
        ::placeholder {
          color: ${(props): string => props.theme.colors.grey.silver};
          opacity: 1;
        }
      `
    }
  }}
`

const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('padding-bottom', '12px')}
  ${ResponsivePXValue('margin-bottom', '6px')}
`

const LoadingContainer = styled.div`
  position: absolute;
  line-height: 0px;
  cursor: pointer; 

  ${ResponsivePXValue('right', '12px')}
  ${ResponsivePXValue('top', '50%')}
  ${ResponsivePXValue('margin-top', '-7px')}
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
`

const SelectContainer = styled.div<{ direction: string, open: boolean, optionsToShow: number, top: number }>`
  position: absolute;
  left: 0;
  display: ${(props): string => props.open ? 'inline-block' : 'none'};
  z-index: 10;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  overflow-x: hidden;
  overflow-y: scroll;
  ${LiteBoxShadow}

  ${(props): CSS => {
    return css`
      ${ResponsivePXValue('max-height', { mobile: `${props.optionsToShow * 40}px`, tablet: `${props.optionsToShow * 44}px`, desktop: `${props.optionsToShow * 48}px` })}
    `
  }}
  
  ${(props) => {
    switch (props.direction) {
      case 'bottom':
        return css`top: ${props.top}px;`
      case 'top':
        return css`bottom: ${props.top}px;`
    }
  }}
  
  ${ResponsivePXValue('margin-top', '1px')}

  ${ResponsivePXValue('padding', '8px 0')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('border-width', '1px')}
`

const IconWrapper = styled.div``

const SelectOptionElement = styled.div<{ selected: boolean, disabled?: boolean, isLastOption?: boolean }>`

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: ${props => !props.isLastOption ? `1px solid ${props.theme.colors.grey.athens}` : 'none'};

  ${ResponsivePXValue('height', '36px')}

  color: ${(props): string => props.selected ? props.theme.colors.green.watercourse : props.theme.colors.grey.stormDust};

  &:hover {
    color: ${(props): string => props.theme.colors.white.floralWhite};
    background-color: ${(props): string => props.theme.colors.green.watercourse};

    border-bottom-color: transparent;
    
    & + div {
      border-bottom-color: transparent;
    }
  }

`
const OptionText = styled.div<{ selected: boolean, disabled: boolean }>`
  font-weight: ${(props): number => props.selected ? 600 : 400};
  font-family: open-sans;
  text-decoration: ${(props): string => props.disabled ? 'line-through' : ''};

  ${ResponsivePXValue('margin-left', '16px')}
  ${ResponsivePXValue('font-size', '12px')}
`

const OptionIcon = styled.div`
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
  ${ResponsivePXValue('margin-right', '16px')}
`

function SelectInputInner(props: SelectInputInnerProps): JSX.Element {
  const {
    placeholder = '',
    label,
    rules,
    showLabel = true,
    name,
    onSearchChange,
    options,
    loading,
    disabled,
    apiEnum,
    meta,
    control,
    form,
    direction = 'bottom',
    optionsToShow = 6,
    allowMultiple = false,
    className,
    wrapperClassName,
    searchable = true,
    border = true,
    noOptionsMsg = 'No Options',
  } = props

  const [state, setState] = useState<SelectInputState>({ ...DEFAULT_STATE })
  const { data, loading: apiLoading } = useGetApiEnumQuery({
    variables: {
      enum: apiEnum,
    },
    skip: !apiEnum,
  })
  const { isOutside, shouldMonitor, ref } = useClickOutside<HTMLDivElement>(false)

  const inputRef: React.RefObject<HTMLInputElement> = useRef()
  const displayRef: React.RefObject<HTMLInputElement> = useRef()
  const optionsRef: React.RefObject<HTMLDivElement> = useRef()
  const theme = useTheme()

  const error = meta.errors?.[0]
  const { value, onChange } = control

  let availableOptions: SelectOption[] = options ? [...options] : []
  let actualOptions: SelectOption[] = options ? [...options] : []

  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
    actualOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  if (state.search && !onSearchChange) {
    availableOptions = availableOptions.filter((option) => {
      return option.title.toLowerCase().indexOf(state.search.toLowerCase()) !== -1
    })
  }

  if (allowMultiple && Array.isArray(value)) {
    value.forEach((val) => {
      const index = availableOptions.findIndex((option) => option.value === val)
      if (index !== -1) {
        availableOptions.splice(index, 1)
      }
    })
  }

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )

  const _handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }))
    onSearchChange?.(e.target.value)
  }

  const _handleChange = (newValue: FormValue, disabledLocally: boolean) => {
    if (disabledLocally) {
      return
    }
    if (allowMultiple) {
      const updatedValue = value && Array.isArray(value) ? [...(value as FormValue[])] : []
      if (updatedValue.includes(newValue)) {
        updatedValue.splice(updatedValue.indexOf(newValue), 1)
      } else {
        updatedValue.push(newValue)
      }
      onChange(updatedValue)
      setState((prevState) => ({ ...prevState, search: '' }))
    } else {
      onChange(newValue)
      setState((prevState) => ({ ...prevState, open: false, search: '' }))
    }
  }

  const _handleBlur = () => {
    if (!allowMultiple) {
      setState((prevState) => ({ ...prevState, search: '', open: false }))
    }
  }

  const _handleFocus = () => {
    const elementHeight = displayRef.current.clientHeight
    setState((prevState) => update(prevState, {
      inputContainerHeight: { $set: elementHeight },
      open: { $set: !state.open },
      search: { $set: '' },
    }))
  }

  const _handleOpenClose = (): void => {
    if (state.open) {
      _handleBlur()
    } else {
      _handleFocus()
    }
  }

  const _handleDisabledOption = (option: SelectOption): boolean => {
    return option.disabled || false
  }

  useEffect(() => {
    isOutside && _handleBlur()
  }, [isOutside])

  useEffect(() => {
    shouldMonitor(state.open)
  }, [state.open])

  useEffect(() => {
    const elementHeight = displayRef.current.clientHeight
    setState((prevState) => update(prevState, {
      inputContainerHeight: { $set: elementHeight },
    }))
  }, [value])

  useEffect(() => {
    if (value && state.open) {
      const index = availableOptions.findIndex((option) => option.value === value)
      const currentScroll = optionsRef.current.scrollTop
      const containerHeight = optionsRef.current.clientHeight
      const itemHeight = optionsRef.current.clientHeight / optionsToShow
      const selectedItemTop = itemHeight * index
      if (selectedItemTop >= currentScroll + containerHeight) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop + itemHeight - containerHeight)
      } else if (selectedItemTop < currentScroll) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop)
      }
    }
  }, [value])

  let inputValue = state.search
  if (value && !state.open) {
    inputValue = availableOptions.find((option) => option.value === value)?.title || ''
  }

  let option: SelectOption
  let optionValue: string
  let optionIndex: number
  return (
    <InputWrapper
      ref={ref}
      className={wrapperClassName}
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled, registerRef }: FormContextProperties) => {
          registerRef?.(name, inputRef)
          return (
            <Container>
              <SelectDisplayElement
                ref={displayRef}
                border={border}
                onClick={_handleOpenClose}
                className={className}
                disabled={disabled || loading || apiLoading || formLoading || formDisabled}
                error={!!error}
                focused={state.open}
                isPlaceholder={!value}>

                <If condition={allowMultiple && Array.isArray(value) && !!value.length}>
                  <ChipsContainer>
                    <For each='optionValue' of={(value as FormValue[]) ?? []}>
                      <Chip
                        key={optionValue}
                        title={actualOptions?.find((option) => option.value === optionValue)?.title ?? optionValue}
                        onRemove={() => _handleChange(optionValue, false)} />
                    </For>
                  </ChipsContainer>
                </If>

                <SelectInputElement
                  className={className}
                  readOnly={!searchable}
                  ref={inputRef}
                  autoComplete='no'
                  disabled={disabled || loading || apiLoading || formLoading || formDisabled}
                  name={name}
                  error={!!error}
                  type='text'
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={_handleSearchChange} />
              </SelectDisplayElement>

              <SelectContainer direction={direction} ref={optionsRef} open={state.open} optionsToShow={optionsToShow} top={state.inputContainerHeight}>
                <Choose>

                  <When condition={!!availableOptions.length}>
                    <For each='option' of={availableOptions} index='optionIndex'>
                      <SelectOptionElement
                        onMouseDown={() => _handleChange(option.value, option.disabled)}
                        selected={allowMultiple ? (value as FormValue[])?.includes(option.value) : option.value === value}
                        key={option?.value?.toString() || 'NULL'}
                        disabled={_handleDisabledOption(option)}
                        isLastOption={optionIndex === availableOptions.length - 1}>
                        <OptionText selected={allowMultiple ? (value as FormValue[])?.includes(option.value) : option.value === value} disabled={option.disabled}>
                          {option.title}
                        </OptionText>
                      </SelectOptionElement>
                    </For>
                  </When>

                  <Otherwise>
                    <SelectOptionElement selected={false}>
                      <OptionText selected disabled={false}>
                          {noOptionsMsg}
                      </OptionText>
                      <OptionIcon onClick={_handleOpenClose}>
                        <Icon icon={LocalIconEnums.CLOSE} color={theme.colors.white.romance} />
                      </OptionIcon>
                    </SelectOptionElement>
                  </Otherwise>

                </Choose>
              </SelectContainer>
              <LoadingContainer>
                <Choose>

                  <When condition={loading || apiLoading || formLoading}>
                    <SmallLoader
                      color={theme.colors.grey.silver} />
                  </When>

                  <When condition={state.open}>
                    <IconWrapper onClick={_handleOpenClose}>
                      <Icon color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_UP} />
                    </IconWrapper>
                  </When>

                  <Otherwise>
                    <IconWrapper onClick={_handleOpenClose}>
                      <Icon color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                    </IconWrapper>
                  </Otherwise>

                </Choose>
              </LoadingContainer>
            </Container>
          )
        }}
      </FormContext.Consumer>
    </InputWrapper>
  )
}

interface SelectInputState {
  search: string
  open: boolean
  inputContainerHeight: number
}

export interface SelectFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  searchable?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  placeholder?: string
  variant?: 'select'
  apiEnum?: string
  options?: SelectOption[]
  optionsToShow?: number
  allowMultiple?: boolean
  className?: string
  wrapperClassName?: string
  direction?: string
  onSearchChange?: (search: string | null) => void
  border?: boolean
  noOptionsMsg?: string
}

const DEFAULT_STATE = {
  search: '',
  open: false,
  inputContainerHeight: 0,
}

export function SelectInput(props: SelectFormInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <SelectInputInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
