import React, { useState, useEffect, useMemo } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { ButtonColors, Button } from '@atoms/buttons'
import { LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { SmallLoader } from '@atoms/notifications'
import { Heading, Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, CartItemFragment, useCustomerQuery, WarehouseFragment, useAddProductToWishlistMutation, StockInfoFragment, BundleCartItemFragment, CartFragment } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '12px 12px 12px 0')}
  ${(props): string => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.gallery}`)};
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('padding', '8px')}
  ${ResponsivePXValue('width', '94px')}
  ${ResponsivePXValue('height', '94px')}
`

const InfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${ResponsivePXValue('gap', '8px')}
  .name {
    margin: 0;
  }
`

const StockContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '4px')}
`

const StockItem = styled.div`
  font-family: 'open-sans';
  font-weight: 600;
  ${ResponsivePXValue('font-size', '10px')}
  background-color: ${(props): string => props.theme.colors.grey.athens};
  color: ${(props): string => props.theme.colors.grey.stormDust};
  ${ResponsivePXValue('padding', '6px 4px')}
`

const OutOfStockContainer = styled.div``

const BottomContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price {
    margin: 0;
  }
`

const CartControls = styled.div`
  display: flex;
  .cart-item-buttons {
    padding: 0;
    ${ResponsivePXValue('width', '30px')}
  }
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('margin-bottom', '8px')}
`

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '30px')}
`

const SelectContainer = styled.div`
  position: relative;
`

const Quantity = styled.div`
  position: absolute;
  ${ResponsivePXValue('top', '11px')}
  ${ResponsivePXValue('left', '12px')}
  font-family: open-sans;
  font-weight: 400;
  ${ResponsivePXValue('font-size', '12px')}
  color: ${(props): string => props.theme.colors.grey.stormDust};
`

const SmallSelect = styled.select`
  flex-grow: 1;
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.silver}`)}
  ${ResponsivePXValue('padding', '8px 30px 8px 34px')}
  border-radius: 0;
  font-family: open-sans;
  font-weight: 400;
  ${ResponsivePXValue('font-size', '12px')}
  outline: none;
  color: ${(props): string => props.theme.colors.grey.stormDust};
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('margin-left', '5px')}
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMy4yNDUgNGwtMTEuMjQ1IDE0LjM3NC0xMS4yMTktMTQuMzc0LS43ODEuNjE5IDEyIDE1LjM4MSAxMi0xNS4zOTEtLjc1NS0uNjA5eiIvPjwvc3ZnPg==) no-repeat;
  appearance: none;
  ${ResponsivePXValue('background-size', '10px 10px')}
  ${ResponsivePXValue('background-position', '64px')}

`

const SmallOption = styled.option<{ isPlaceholder?: boolean }>`
  color: ${(props): string => props.isPlaceholder
    ? props.theme.colors.grey.silver
    : props.theme.colors.grey.stormDust};
`

const SmallInput = styled.input`
  flex-grow: 1;
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.desertStorm}`)}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('padding', '0 6px')}
  border-radius: 0;
  font-family: open-sans;
  ${ResponsivePXValue('font-size', '11px')}
  outline: none;
  color: ${(props): string => props.theme.colors.green.bottleGreen};
  background-color: ${(props): string => props.theme.colors.white.pampas};
  ${ResponsivePXValue('margin-left', '5px')}
  ${ResponsivePXValue('width', '54px')}
`

const LoadingContainer = styled.div`
  ${ResponsivePXValue('width', '59px')}
  ${ResponsivePXValue('height', '20px')}
`

export interface MobileProductCartCardProps {
  cartItem: CartItemFragment
  cart: CartFragment
  onClose?: () => void
}

interface MobileProductCartCardState {
  quantity: string
}

const DEFAULT_STATE: MobileProductCartCardState = {
  quantity: '0',
}

export function MobileProductCartCard({ cartItem, cart, onClose }: MobileProductCartCardProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: customerData } = useCustomerQuery()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [addProductToWishlist, { loading: addWishlistLoading }] = useAddProductToWishlistMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const [state, setState] = useState<MobileProductCartCardState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const theme = useTheme()
  const navigate = useNavigate()

  const shouldShowUpdateOptions = useMemo(() => {
    if (cartItem.product.__typename === 'ConfigurableProduct') {
      return true
    }
    if (cartItem.product.__typename === 'BundleProduct') {
      for (let i = 0; i < cartItem.product.items.length; i++) {
        if (!cartItem.product.items[i].required) {
          return true
        }
        if (cartItem.product.items[i].options.length > 1) {
          return true
        }
        for (let o = 0; o < cartItem.product.items[i].options.length; o++) {
          if (cartItem.product.items[i].options[o].canChangeQuantity) {
            return true
          }
        }
      }
    }
    return false
  }, [cartItem?.product])

  const _handleRemove = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: cartItem.uid,
        },
        optimisticResponse: () => {
          return {
            removeItemFromCart: {
              cart: {
                ...cart,
                items: [...cart.items.filter((itm) => itm.uid !== cartItem.uid)],
              },
              __typename: 'RemoveItemFromCartOutput',
            },
          }
        },
      })
      addToast({
        message: 'Item removed from cart',
        appearance: 'error',
      })
    } catch (err) {
      addToast({
        message: 'Oops. Something went wrong. Please try again later.',
        appearance: 'error',
      })
    }
  }

  const _handleMoveToWishlist = async (): Promise<void> => {
    try {
      await addProductToWishlist({
        variables: {
          wishlistId: customerData.currentCustomer.wishlists[0].id,
          sku: cartItem.product.sku,
          quantity: 1,
        },
      })
      addToast({
        message: 'Product added to favorites',
        appearance: 'success',
      })
    } catch (err) {
      addToast({
        message: 'Oops. Something went wrong. Please try again later.',
        appearance: 'error',
      })
    }
    await removeItemFromCart({
      variables: {
        cartId,
        cartItemUid: cartItem.uid,
      },
    })
  }

  const _handleChangeQuantity = async (quantity: string): Promise<void> => {
    let newQuantity: number | null = null
    if (quantity === '10+') {
      newQuantity = 10
    } else if (!isNaN(parseInt(quantity))) {
      newQuantity = parseInt(quantity)
    } else {
      setState((prevState) => update(prevState, {
        quantity: { $set: cartItem.quantity },
      }))
    }
    if (newQuantity !== null) {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid: cartItem.uid,
            quantity: newQuantity,
          },
          optimisticResponse: () => {
            return {
              updateCartItems: {
                cart: {
                  ...cart,
                  items: [...cart.items.map((itm) => {
                    if (itm.uid === cartItem.uid) {
                      return {
                        ...itm,
                        quantity: newQuantity,
                      }
                    }
                    return itm
                  })],
                },
                __typename: 'UpdateCartItemsOutput',
              },
            }
          },
        })
      } catch (e) {
        setState((prevState) => update(prevState, {
          quantity: { $set: cartItem.quantity + '' },
        }))
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }
  }

  const _handleInputQuantityChange = (quantity: string): void => {
    setState((prevState) => update(prevState, {
      quantity: { $set: quantity },
    }))
  }

  const _handleGoToProduct = (): void => {
    if (onClose) {
      onClose()
    }
    navigate(`/${cartItem.product.urlKey}`)
  }

  const _handleReConfigure = (): void => {
    navigate(`/${cartItem.product.canonicalUrl}`)
  }

  useEffect(() => {
    setState((prevState) => update(prevState, {
      quantity: { $set: cartItem.quantity + '' },
    }))
  }, [cartItem.quantity])

  const buttonColors: ButtonColors = {
    color: theme.colors.grey.gunSmoke,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.red.cinnabar,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.gallery,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  }

  const price = SiteHelper.formatCurrency(cartItem.prices.priceIncludingTax)
  const specialPrice = cartItem.product.specialPrice

  const extraLabels: string[] = useMemo(() => {
    const responseItems: string[] = []
    if (cartItem.__typename === 'ConfigurableCartItem') {
      cartItem.configurableOptions.forEach((option) => {
        responseItems.push(`${option.optionLabel}: ${option.valueLabel}`)
      })
    }
    return responseItems
  }, [cartItem])

  let quantity: string
  let warehouseIndex: number
  let warehouse: WarehouseFragment
  let stock: StockInfoFragment = cartItem.product.stockInfo
  if (cartItem.__typename === 'ConfigurableCartItem') {
    stock = cartItem.configuredVariant.stockInfo
  }

  return (
    <Container>
      <ImageContainer onClick={_handleGoToProduct}>
        <ResponsiveImage image={cartItem.product.coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <Heading variant='h5' className='name' onClick={_handleGoToProduct}>
          {cartItem.product.name}{extraLabels.length ? ` (${extraLabels.join(', ')})` : ''}
        </Heading>
        <PriceContainer>
          <Title variant='t9'>
            {price}
          </Title>
          <If condition={!!specialPrice}>
            <Paragraph variant='p3' decoration='line-through' color={theme.colors.grey.gunSmoke}>
              {specialPrice}
            </Paragraph>
          </If>
        </PriceContainer>
        <StockContainer>
          <Choose>
            <When condition={cartItem.__typename === 'BundleCartItem'}>
              <Choose>
                <When condition={shouldShowUpdateOptions}>
                  <Button
                    title={`UPDATE BUNDLE OPTIONS (${(cartItem as BundleCartItemFragment).bundleOptions.reduce((total, item) => total + item.values.reduce((valTotal, val) => valTotal + val.quantity, 0), 0)})`}
                    size='medium'
                    variant='layout'
                    onClick={_handleReConfigure}
                  />
                </When>
                <Otherwise>
                  <StockItem>
                    {(cartItem as BundleCartItemFragment).bundleOptions.reduce((total, item) => total + item.values.reduce((valTotal, val) => valTotal + val.quantity, 0), 0)} ITEMS IN BUNDLE
                  </StockItem>
                </Otherwise>
              </Choose>
            </When>
            <Otherwise>
              <Paragraph variant='p2' bold>
                Stock:
              </Paragraph>
              <For each='warehouse' of={stock.sources} index='warehouseIndex'>
                <StockItem key={warehouseIndex}>
                  {warehouse.location} ({warehouse.qty})
                </StockItem>
              </For>
            </Otherwise>
          </Choose>
        </StockContainer>
        <OutOfStockContainer>
          <If condition={cartItem.product.stockStatus === ProductStockStatus.OUT_OF_STOCK}>
            <Paragraph variant='p2' color={theme.colors.red.cinnabar}>
              Sorry, we&apos;re out of stock
            </Paragraph>
          </If>
        </OutOfStockContainer>
        <BottomContainer>
          <CartControls>
            <Button
              onClick={_handleRemove}
              colors={buttonColors}
              className='cart-item-buttons'
              loading={removeLoading}
              disabled={addWishlistLoading}
              icon={LocalIconEnums.TRASH_OUTLINE} />
            <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
              <Button
                onClick={_handleMoveToWishlist}
                colors={buttonColors}
                className='cart-item-buttons'
                disabled={removeLoading}
                loading={addWishlistLoading}
                icon={LocalIconEnums.HEART_OUTLINE} />
            </If>
          </CartControls>
          <QuantityContainer>
            <Choose>
              <When condition={quantityLoading}>
                <LoadingContainer>
                  <SmallLoader />
                </LoadingContainer>
              </When>
              <When condition={cartItem.quantity > 9}>
                <Tag variant='t3' className='price'>Qty:</Tag>
                <SmallInput
                  value={state.quantity}
                  type='text'
                  onBlur={(e) => _handleChangeQuantity(e.target.value)}
                  onChange={(e) => _handleInputQuantityChange(e.target.value)} />
              </When>
              <Otherwise>
                <SelectContainer>
                  <SmallSelect placeholder='Select' value={cartItem.quantity + ''} onChange={(e) => _handleChangeQuantity(e.target.value)}>
                    <For each='quantity' of={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+']}>
                      <SmallOption key={quantity} value={quantity}>{quantity}</SmallOption>
                    </For>
                  </SmallSelect>
                  <Quantity>Qty: </Quantity>
                </SelectContainer>
              </Otherwise>
            </Choose>
          </QuantityContainer>
        </BottomContainer>
      </InfoContainer>

    </Container>
  )

}
