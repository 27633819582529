import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { BoxShadow, ResponsivePXValue } from '@components/Theme'
import { MenuCategoryBaseFragment } from '@hooks/api/index'
import { Page } from '@organisms/content'

type GroupCategory = MenuCategoryBaseFragment['children'][0]['children'][0]
type SubCategory = MenuCategoryBaseFragment['children'][0]['children'][0]['children'][0]

const Container = styled.div`
  position: absolute;
  columns: 4;
  width: 100%;
  /* ${ResponsivePXValue('height', '430px')}; */
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  ${ResponsivePXValue('top', '55px')}
  ${ResponsivePXValue('padding', '16px 64px')}
  ${ResponsivePXValue('column-gap', '64px')}
  
`

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  ${ResponsivePXValue('top', '55px')}
  display: flex;
  flex-direction: column;
  ${BoxShadow}

`

const CategoryGroup = styled.div`
  display: inline-block;
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('margin', '8px 0')}
`

const CategoryHeader = styled.div`
  ${ResponsivePXValue('padding', '8px')}
  background-color: ${(props): string => props.theme.colors.green.watercourse};
  color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('border-radius', '2px')}
  font-weight: 700;
  font-family: open-sans;
  cursor: pointer;
`

const SubCategoryContainer = styled.div``

const SubCategoryItem = styled.div`
  ${ResponsivePXValue('padding', '6px 8px')}
  ${ResponsivePXValue('margin', '2px 0')}
  ${ResponsivePXValue('font-size', '12px')}
  font-weight: 600;
  font-family: open-sans;
  cursor: pointer;
  &:hover {
    font-weight: 800;
  }
`

const SelectedSubCategoryItem = styled.div`
  ${ResponsivePXValue('padding', '6px 8px')}
  ${ResponsivePXValue('margin', '2px 0')}
  ${ResponsivePXValue('font-size', '12px')}
  font-weight: 600;
  font-family: open-sans;
  cursor: pointer;
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.black.pureBlack}`)}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface DesktopCategoryMenuProps {
  mainNav: MenuCategoryBaseFragment
  onSelect?: (path: string) => void
  onClose?: () => void
}

export function DesktopCategoryMenu({ mainNav, onSelect, onClose }: DesktopCategoryMenuProps): JSX.Element {

  const [path, setPath] = useState<string | null>(null)
  const [id, setId] = useState<number | null>(null)
  const location = useLocation()
  let groupCategory: GroupCategory
  let subCategory: SubCategory

  useEffect(() => {
    if (mainNav.menuContent) {
      if (id !== mainNav.id) {
        setId(mainNav.id)
        setPath(location.pathname)
      } else if (path !== location.pathname) {
        onClose?.()
      }
    }
  }, [location.pathname, mainNav.id])

  return (
    <Choose>
      <When condition={!!mainNav.menuContent}>
        <ContentContainer>
          <Page page={mainNav.menuContent} />
        </ContentContainer>
      </When>
      <Otherwise>
        <Container>
          <For each='groupCategory' of={mainNav.children}>
            <CategoryGroup key={groupCategory.id}>
              <CategoryHeader onClick={() => onSelect(groupCategory.canonicalUrl)}>
                {groupCategory.name}
              </CategoryHeader>
              <If condition={!!groupCategory.children}>
                <SubCategoryContainer>
                  <For each='subCategory' of={groupCategory.children}>
                    <Choose>
                      <When condition={location.pathname.includes(subCategory.canonicalUrl)}>
                        <SelectedSubCategoryItem key={subCategory.id} onClick={() => onSelect(subCategory.canonicalUrl)}>
                          {subCategory.name}
                        </SelectedSubCategoryItem>
                      </When>
                      <Otherwise>
                        <SubCategoryItem key={subCategory.id} onClick={() => onSelect(subCategory.canonicalUrl)}>
                          {subCategory.name}
                        </SubCategoryItem>
                      </Otherwise>
                    </Choose>
                  </For>
                </SubCategoryContainer>
              </If>
            </CategoryGroup>
          </For>
        </Container>
      </Otherwise>
    </Choose>

  )

}
